/**
 *  @Author: qp
 *  @Date: 2023-12-19 14:40:54
 * @Last Modified by: qp
 * @Last Modified time: 2024-07-18 21:05:05
 *  @name 全局状态
 *  @description 后期要添加避免滥用的机制
 **/

"use client";
import {
  createContext,
  useContext,
  useEffect,
  // useLayoutEffect,
  useState,
} from "react";
import { assocPath, path } from "ramda";
import { getUserInfo } from "@/hooks/useUser";
// import { importJs } from "@/utils/utils";
import { initWx } from "@/utils/wx";

export const GlobalContext = createContext();

const _useGlobal = (store, setStore) => {
  const exportGlobal = (keys) => {
    const update = (value) => {
      const newValue = assocPath(keys, value, store);
      setStore(newValue);
    };
    const _store = path(keys, store);
    return [_store, update];
  };

  return {
    exportGlobal,
    setStore,
    store,
  };
};

export const useGlobal = (keys) => {
  const {exportGlobal} = useContext(GlobalContext);
  return exportGlobal(keys);
};

const ClientLayout = ({ children }) => {
  const [store, setStore] = useState({ user: { loading: false, islogin: false, loaded: false } });
  const { exportGlobal } = _useGlobal(store, setStore);
  
  useEffect(() => {
    initWx();
    // addVConsole();
    if (process.env.RUNTIME_ENV !== "production") {
      const Vconsole = require("vconsole");
      new Vconsole();
    }
  }, []);

  useEffect(() => {
    if (
      !store?.user?.islogin &&
      !store?.user?.loading &&
      !store?.user?.loaded
    ) {
      setStore({
        user: {
          loading: true,
          loaded: false,
          ...store?.user,
        },
        ...store,
      });
      getUserInfo().then((user) => {
        if (user?.code === 0) {
          setStore({
            ...store,
            user: {
              loading: false,
              loaded: true,
              ...user?.data,
            },
          });
        } else {
          setStore({
            ...store,
            user: {
              loading: false,
              loaded: true,
            },
          });
        }
      });
    }
  }, [store?.user?.islogin, store?.user?.loading, store?.user?.loaded]);

  // const addVConsole = () => {
  //   if (process.env.RUNTIME_ENV === "test") {
  //     importJs(
  //       "https://static.taocheche.com/vconsole.min.js",
  //       null,
  //       function () {
  //         if (VConsole) new VConsole();
  //       }
  //     );
  //   }
  // };

  return (
    <GlobalContext.Provider value={{setStore, store, exportGlobal}}>
      {children}
    </GlobalContext.Provider>
  );
};

export default ClientLayout;
